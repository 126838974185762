import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/vercel/path0/node_modules/gatsby-theme-carbon/src/templates/Default.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p><em parentName="p">{`Test Week`}</em></p>
    <p>{`Split Jerk 1-1-1-1-1 to a 1RM`}</p>
    <p>{`then,`}</p>
    <p>{`4 Rounds for time of:`}</p>
    <p>{`5-S2OH (155/105)`}</p>
    <p>{`10-Burpees`}</p>
    <p>{`200ft Shuttle Run (in 50′ increments)`}</p>
    <p>{`Rest 1:00 between rounds`}</p>
    <p>{`*`}{`*`}{`Bonus WOD`}{`*`}{`*`}</p>
    <p>{`1500M Row for time.`}</p>
    <p><strong parentName="p"><em parentName="strong">{`*`}{`If you are competing in the Pre Open and missed any of the workouts
make them up soon.  We will be finalizing the standings on Saturday and
announcing the winners on Sunday.`}</em></strong></p>
    <p><strong parentName="p">{`*`}{`Saturday, February 4th, we will have our 3rd Annual Sports Nutrition
seminar with Dawn from Sports Nutrition 2 Go in Cincinnati. The seminar
will be from 12-2pm. \\$35/person for the seminar. We will need 20 people
in order to have this so please email Daniel\\@crossfitlouisvilleeast.com
if you’d like to attend.`}</strong></p>
    <p><strong parentName="p">{`Subjects covered will include:`}</strong></p>
    <p><strong parentName="p">{`Hydration—How impacts performance, electrolytes, what to drink`}</strong></p>
    <p><strong parentName="p">{`Breakfast—effects afternoon performance by 5% and effects appetite`}</strong></p>
    <p><strong parentName="p">{`Nutrient Timing—what to eat before/after, when`}</strong></p>
    <p><strong parentName="p">{`Sleep and Nutrition on performance---Discuss percentages on outcomes`}</strong></p>
    <p><strong parentName="p">{`Discuss different nutrition philosophies`}</strong></p>
    <p><strong parentName="p">{`Supplements and Labs: what to take and which labs to draw`}</strong></p>
    <p><strong parentName="p">{`Also: She will cover what ever the audience would like! If you would
like to attend and you want to forward questions ahead of time please
send them to: Daniel\\@crossfitlouisvilleeast.com.`}</strong></p>
    <p><strong parentName="p">{`*`}{`Want to learn the exact number of calories your body burns each day?
Metabolic Testing will be offered before and after the seminar as well!
\\$65 for Metabolic Testing.`}</strong></p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      